import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainFooter from "../../footer/MainFooter";

import CallToAction1 from "./CallToAction/CallToAction1";
import Hero from "./Hero/Hero";
import Industries from "./Industries/Industries";
import LiveShare from "./LiveInsights/LiveShare";
import "./salesx.scss";
import Service from "./Service/Service";
const MerchXHome = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Hero />
      <Service />

      <LiveShare />
      <Industries />
      <CallToAction1 />
      <MainFooter />
    </div>
  );
};

export default MerchXHome;
